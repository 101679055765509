.faq-content-container {
    width: 100%;
    margin: auto;
    padding-top: 36px;
    padding-bottom: 96px;
    min-height: 70vh;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    .faq-sub-header {
        margin-top: 40px;
        font-size: 20px;
        font-weight: 700;
        color: #686868;
        line-height: 24px;
    }

    .faq-main-header {
        font-size: 48px;
        font-weight: 700;
        line-height: 60px;
    }

    .faq-qna-container {
        width: 100%;
        margin-top: 16px;
    }

    .faq-qna-item-container {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .faq-qna-item-collapse {
            background-color: #ffff;
            box-shadow: 1px 1px 12px 0px rgba(0,54,185,0.25);
            border: 1px solid #E7E7E7;
            width: 50%;
        }

        .faq-question-number {
            color: @primary-color;
            font-weight: bold;
        }
    }
}

@media (max-width: 1024px) {
    .faq-content-container {
        .faq-qna-item-container {
            .faq-qna-item-collapse {
                width: 70%;
            }
        }
    }
}

@media (max-width: 820px) {
    .faq-content-container {
        .faq-sub-header {
            margin-top: 12px;
            font-size: 24px;
        }
    
        .faq-main-header {
            font-size: 32px;
        }
        
        .faq-qna-item-container {
            .faq-qna-item-collapse {
                width: 70%;
            }
        }
    }
}

@media (max-width: 576px) {
    .faq-content-container {       
        .faq-qna-container {
            margin-top: 0px;
        }

        .faq-sub-header {
            margin-top: 12px;
            font-size: 16px;
        }
    
        .faq-main-header {
            font-size: 20px;
        }
        
        .faq-qna-item-container {
            .faq-qna-item-collapse {
                width: 70%;
            }
        }
    }
}
@primary-color: #0036B9;@secondary-color: #FFAC00;@content-background-color: #fff;