.pricing-container-content {
    width: 100%;
    margin: auto;
    padding-top: 36px;
    padding-bottom: 96px;
    min-height: 70vh;
    align-items: center;
    justify-content: space-around;
    display: flex;
    flex-direction: column;
}

.pricing-header-container {
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
  
.pricing-subheading {
    color: @primary-color;
    font-size: 28px;
    margin-bottom: 8px;
    font-weight: 700;
}

.pricing-heading {
    width: 100%;
    font-size: 52px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 16px;
    
    .pricing-heading-1 {
        color: @secondary-color;
    }
    .pricing-heading-2 {
        color: @primary-color;
    }
}
  
.pricing-description {
    width: 100%;
    text-align: center;
    font-size: 20px;
    color: rgb(105, 105, 105);
}
  
.pricing-plans-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: stretch;
    position: relative;
    padding: 0px 20px;
}
  
.plan {
    width: 100%;
    max-width: 320px;
    margin-top: 64px;
    margin-left: 16px;
    margin-right: 16px;
    text-align: center;
    padding: 32px;
    border-radius: 12px;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    background-color: white;
    color: #1a202c;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    flex: 1;
}

.plan-features-btn-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.plan-features-btn{
    min-width: 120px;
    margin-top: 40px;
    padding: 12px 24px;
    border-radius: 4px;
    border-width: 0px;
    background-color: @primary-color;
    color: white;
    font-size: 12px;
    font-weight: bold;
}

.basic-features{
    h3 {
        text-align: center;
    }

    li {
        text-align: justify;
    }
}

.advanced-features {
    h3 {
        text-align: left;
    }

    li {
        text-align: justify;
    }
}

.template-note {
    color: #757575;
    text-align: justify;
}

.template-note-featured{
    color: white;
    text-align: justify;
}
  
.plan:last-child {
    margin-right: 0;
}
  
.plan .plan-highlight {
    border-radius: 12px 12px 0 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 8px;
}
  
.plan.featured {
    background: linear-gradient(135deg, rgba(76, 81, 191, 1) 0%, rgba(102, 126, 234, 1) 100%);
    color: white;
}
  
.plan.featured .plan-highlight {
    display: none;
}
  
.plan.featured .credit {
    color: rgba(255, 255, 255, 0.8);
}
  
.plan.featured .feature:not(.main-feature) {
    color: rgba(255, 255, 255, 0.6);
}
  
.plan-header {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    line-height: 1.75;
    padding: 32px 0;
}
  
.plan-header .name {
    font-weight: bold;
    font-size: 20px;
}
  
.plan-header .price {
    font-weight: bold;
    font-size: 36px;
    margin: 4px 0;
}
  
.plan-header .credit {
    color: #718096;
    font-weight: bold;
    letter-spacing: 0.1em;
}
  
.plan-features {
    display: flex;
    flex-direction: column;
    padding: 32px 0;
    border-top: 2px solid #e2e8f0;
    border-bottom: 2px solid #e2e8f0;
    flex: 1;
}
  
.plan-features .feature {
    margin-top: 8px;
    font-weight: 500;
}
  
.plan-features .feature:first-child {
    margin-top: 0;
}
  
.plan-features .feature:not(.main-feature) {
    color: #4a5568;
}
  
.plan-features .main-feature {
    font-size: 16x;
    font-weight: bold;
    letter-spacing: 0.025em;
}
  
.plan-action {
    padding: 32px;
}

.plan-action-enterprise {
    padding: 28px;
    text-align: center;
    display: flex;
    justify-content: space-between;
}

.plan-action-enterprise .half-button {
    display: inline-block;
    width: 40%;
    color: white;
    background-color: @primary-color;
    border-radius: 8px;
    text-align: center;
    text-decoration: none;
    margin: 0 2%;
    transition: transform 0.3s, box-shadow 0.3s;
}
  
.buy-now-button {
    border-radius: 8px;
    text-transform: uppercase;
    padding: 1rem;
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;
    border-width: 0px;
}

.features-section {
    margin-top: 16px;
    text-align: left;
}

.features-heading {
    font-weight: bold;
    margin-bottom: 8px;
}

.non-featured-btn {
    background-color: @primary-color;
    color: white;
}

.featured-btn {
    background-color: white;
    color: black;
}

.plan-highligh-head-featured {
    background: linear-gradient(115deg, #6415ff, #7431ff, #8244ff, #8e56ff, #9a66ff);
}

.plan-highligh-head-non-featured {
    background: linear-gradient(115deg, rgba(56, 178, 172, 1) 0%, rgba(129, 230, 217, 1) 100%);
}
  
.buy-now-button:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    transform: translateX(2px) translateY(-2px);
}
  
.buy-now-button:focus {
    outline: 4px solid transparent;
    outline-offset: 4px;
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}
  
.decorator-blob {
    pointer-events: none;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 16rem;
    width: 16rem;
    opacity: 0.25;
    transform: translateX(-50%) translateY(50%);
    z-index: -20;
}

@media (max-width: 1024px) {
    .pricing-subheading {
        font-size: 20px;
        font-weight: 700;
    }

    .pricing-heading {
        font-size: 40px;
        font-weight: 700;
    }

    .pricing-description {
        font-size: 16px;
    }

    .pricing-plans-container {
        flex-direction: column;
    }    
    
    .plan {
        margin-right: 0px;
    }
}

@media (max-width: 576px) {
    .pricing-subheading {
        font-size: 20px;
        font-weight: 700;
    }

    .pricing-heading {
        font-size: 32px;
        font-weight: 700;
    }

    .pricing-description {
        font-size: 12px;
    }

    .pricing-plans-container {
        flex-direction: column;
    }

    .plan {
        max-width: 240px;
        margin-right: 0px;
    }
}

@media (max-width: 344px) {
    .plan {
        margin-left: 0px;
        margin-right: 0px;
    }
}
  
@primary-color: #0036B9;@secondary-color: #FFAC00;@content-background-color: #fff;