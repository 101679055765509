.language-switcher-container {
    display: flex;
    align-items: center;
    justify-content: center;

    p span:not(:first-child) {
        border-left: 1px solid #ffffff;
    }

    p span{
        cursor: pointer;
        padding: 0px 8px;
    }
}

&.blacked .language-switcher-container {
    p span:not(:first-child) {
        border-left: 1px solid #000000;
    }
}

.language-switcher-divider {
    margin: 0;
    background-color: #d9d9d9;
}

.language-dropdown .ant-select-selector {
    width: 80px !important;
    color: #808080;
    border: 1px solid#808080 !important;
}

.language-dropdown .ant-select-arrow {
    color: #808080;
}

.text {
    color: #ffffff;
}

&.blacked .text {
    color: #000;
}
@primary-color: #0036B9;@secondary-color: #FFAC00;@content-background-color: #fff;