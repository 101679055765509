@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(120px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.contact-us-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: auto;
    padding-top: 80px;
    padding-bottom: 96px;
    min-height: 70vh;
    align-items: center;
    justify-content: space-around;
    opacity: 0; /* Initial state */
    transform: translateY(120px); /* Initial state */
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.contact-us-container.animate {
    opacity: 1;
    transform: translateY(0);
}

.contact-us-image-column {
    height: 440px;
    
    .contact-us-skeleton-image { 
        width: 548px !important;
        height: 440px !important;
    }
}

.contact-us-image {
    height: 100%;
}

.contact-us-text-content {
    margin-top: 64px;
}

.contact-us-subheading {
    font-size: 20px;
    font-weight: 700;
    margin: 16px;
    color: @primary-color;
}

.contact-us-heading {
    color: @primary-color;
    font-size: 48px;
    font-weight: 900;
    text-align: left;
    margin: 16px;

    .text-secondary {
        color: @secondary-color;
    }
}

.contact-us-description {
    margin: 16px;
    font-size: 16px;
    line-height: 1.75;
    color: rgb(105, 105, 105);
    display: flex;
    flex-direction: column;
}

.contact-us-form {
    margin: 16px;

    .contact-us-email-input {
        border: 2px solid rgb(105, 105, 105);
        padding: 12px 20px;
        border-radius: 8px;
        transition: border-color 300ms;
    }

    .contact-us-email-input:hover {
        border-color: @primary-color;
    }

    .submit-btn {
        border-width: 0px;
        margin-left: 24px;
        margin-top: 24px;
        padding: 12px 20px;
        font-size: 16px;
        font-weight: 700;
        border-radius: 8px;
        background-color: @primary-color;
        color: #FFFFFF;
        cursor: pointer;
        transition: background-color 300ms;
    }

    .submit-btn:hover {
        background-color: #667EEA;
    }
}

.description-office-multi-line {
    display: none;
}

.description-office-1-line {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    img {
        margin-right: 8px;
    }

    span {
        display: flex;
        align-items: center;
    }
}

.description-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    img {
        margin-right: 8px;
    }

    a {
        color: @primary-color;
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }

    span {
        display: flex;
        align-items: center;
    }
}

@media (max-width: 1024px) {
    .contact-us-container {
        justify-content: center;
        flex-direction: column;
        min-height: 50vh;
    }

    .contact-us-heading {
        font-size: 38px;
        font-weight: 700;
    }

    .contact-us-image-column {
        height: 300px;
    }

    .contact-us-description {
        font-size: 16px;
    }
}

@media (max-width: 820px) {
    .contact-us-heading {
        font-size: 26px;
        font-weight: 700;
    }

    .contact-us-image-column {
        height: 200px;
    }

    .contact-us-description {
        font-size: 12px;
    }
}

@media (max-width: 769px) {
    .contact-us-image-column {
        height: 240px;
    }
}

@media (max-width: 576px) {
    .description-office-1-line {
        display: none;
    }

    .description-office-multi-line {
        display: flex;
        flex-direction: column;
    }

    .contact-us-subheading {
        text-align: center;
    }

    .contact-us-heading {
        text-align: center;
    }

    .contact-us-description {
        text-align: center;
        font-size: 12px;
    }

    .contact-us-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .contact-us-email-input {
            font-size: 12px;
            text-align: center;
        }

        .submit-btn {
            font-size: 12px;
            margin-left: 0px;
        }
    }

    .description-item {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-bottom: 8px;
    
        img {
            margin-top: 20px;
            margin-right: 0px;
        }
    
        a {
            color: @primary-color;
            text-decoration: none;
        }
    
        a:hover {
            text-decoration: underline;
        }
    
        span {
            display: flex;
            align-items: center;
        }
    }
}

@primary-color: #0036B9;@secondary-color: #FFAC00;@content-background-color: #fff;