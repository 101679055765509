.whatsapp-chat-modal {
    position: fixed;
    right: 20px;
    width: 400px;
    z-index: 1100;
    display: flex;
    justify-content: center;
    align-items: center;
    transform-origin: bottom right;
    animation: slide-up 0.5s cubic-bezier(0.8, 0.1, 0.1, 0.8) forwards;
}

.whatsapp-chat-modal.closing {
    animation: slide-down 0.1s ease-out forwards;
}

.whatsapp-chat-modal-content {
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.whatsapp-chat-modal-header {
    display: flex;
    align-items: center;
    padding: 12px;
    background-color: #075e54;
    color: white;
}

.whatsapp-chat-modal-logo {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 12px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.whatsapp-chat-modal-logo img {
    width: 32px;
    height: 32px;
}

.whatsapp-chat-modal-title h2 {
    margin: 0;
    font-size: 16px;
}

.whatsapp-chat-modal-title span {
    font-size: 12px;
    color: #d4d4d4;
}

.close-button {
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
    margin-left: auto;
}

.whatsapp-chat-modal-body {
    padding: 16px;
    display: flex;
    flex-direction: column;
    background-image: url('../../assets/images/whatsapp-background.jpg'); /* Add your background image here */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.chat-message {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    padding: 12px;
    margin-left: 20px;
    position: relative;
    width: 60%;
}

.chat-bubble {
    display: flex;
    flex-direction: column;
}

.chat-time {
    font-size: 12px;
    color: #888;
    position: absolute;
    bottom: 4px;
    right: 12px;
}

.tri-right.border.left-top:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: -40px;
    right: auto;
    top: -8px;
    bottom: auto;
    border: 32px solid;
    border-color: #666 transparent transparent transparent;
}

.tri-right.left-top:after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: -20px;
    right: auto;
    top: 0px;
    bottom: auto;
    border: 22px solid;
    border-color: white transparent transparent transparent;
}

.topic-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 12px;
}

.topic-buttons button {
    background-color: #075e54;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 12px 12px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 12px;
}

.topic-buttons button:hover {
    background-color: #064d43;
}

.whatsapp-chat-modal-footer {
    display: flex;
    padding: 12px;
    background-color: white;
    border-top: 1px solid #ccc;
    align-items: center;
}

input {
    flex: 1;
    border: 0px;
    resize: none;
    height: 12px;
    margin-right: 12px;
}

input:focus {
    outline: none;
}

.send-button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #075e54;
}

.send-icon {
    font-size: 20px;
}

.whatsapp-button-container {
    position: fixed;
    width: 52px;
    height: 52px;
    bottom: 20px;
    right: 20px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50%;
    text-align: center;
    font-size: 32px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: bottom 0.3s ease;
    cursor: pointer;
    border: 0px;
}

.whatsapp-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.whatsapp-button img {
    width: 24px;
    height: 24px;
}

@keyframes slide-up {
    from {
        transform: translateY(52px) scale(0.5);
        opacity: 0;
    }
    to {
        transform: translateY(0) scale(1);
        opacity: 1;
    }
}

@keyframes slide-down {
    from {
        transform: translateY(0) scale(1);
        opacity: 1;
    }
    to {
        transform: translateY(52px) scale(0.5);
        opacity: 0;
    }
}

@media (max-width: 576px) {
    .whatsapp-chat-modal {
        width: 350px; 
    }
}

@media (max-width: 375px) {
    .whatsapp-chat-modal {
        width: 330px; 
    }
}

@media (max-width: 360px) {
    .whatsapp-chat-modal {
        width: 320px; 
    }
}

@media (max-width: 344px) {
    .whatsapp-chat-modal {
        width: 300px; 
    }
}

@media (max-width: 320px) {
    .whatsapp-chat-modal {
        width: 280px; 
    }
}

@media (max-width: 300px) {
    .whatsapp-chat-modal {
        width: 260px; 
    }
}

@primary-color: #0036B9;@secondary-color: #FFAC00;@content-background-color: #fff;