.footer-container {
    background-color: @primary-color;
    color: #f7fafc;
}
  
.footer-content {
    max-width: 1200px;
    margin: auto;
    padding-top: 16px;
    padding-bottom: 16px;
}
  
.footer-row {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
  
.footer-logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.footer-logo-img {
    width: 32px;
}
  
.footer-logo-text {
    margin-left: 12px;
    font-size: 24px;
    font-weight: bold;
}
  
.footer-links-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-size: 16px;
}
  
.link {
    color: white;
    padding-bottom: 4px;
    margin: 8px 16px;
    border-bottom: 2px solid transparent;
    text-decoration:none;
    transition: color 300ms, border-color 300ms;
}
  
.link:hover {
    color: #cbd5e0;
    border-color: #cbd5e0;
}
  
.social-links-container {
    margin-top: 40px;
      
    svg {
        width: 20px;
        height: 20px;
    }
}

.social-link {
    display: inline-block;
    color: #f7fafc;
    transition: color 300ms;
    margin: 0 16px;
}
  
.social-link:hover {
    color: #718096;
}

  
.copyright-text {
    text-align: center;
    margin-top: 40px;
    font-size: 16px;
    color: #b4b4b4;
}

@media (max-width: 769px) {
    .footer-logo-text {
        font-size: 20px;
    }

    .copyright-text {
        font-size: 12px;
    }

    .footer-links-container {
        font-size: 12px;
    }
}

@media (max-width: 576px) {
    .footer-logo-text {
        font-size: 16px;
    }
}
  
@primary-color: #0036B9;@secondary-color: #FFAC00;@content-background-color: #fff;