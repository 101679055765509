@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.interests-questions-container {
    display: flex;
    width: 100%;
    margin: auto;
    padding-top: 60px;
    padding-bottom: 96px;
    border-radius: 150px 0px 150px 0px;
    margin: 8% 0% 8% 0%;
    align-items: center;
    text-align: justify;
    justify-content: space-around;
    background-color: aliceblue;

    @import url('https://fonts.googleapis.com/css?family=Poppins:900i');

    .interests-container {
        opacity: 0; /* Initial state */
        transform: translateY(120px); /* Initial state */
        transition: opacity 1s ease-out, transform 1s ease-out;

        .interests-heading {
            font-size: 40px;
            color: @primary-color;
        }

        .wrapper {
            display: flex;
            justify-content: center;
          }
          
        .cta {
            display: flex;
            padding: 10px 45px;
            text-decoration: none;
            font-family: 'Poppins', sans-serif;
            font-size: 40px;
            color: white;
            background: @secondary-color;
            transition: 1s;
            box-shadow: 6px 6px 0 black;
            border-radius: 25px;
            transform: skewX(-15deg);
        }
        
        .cta:focus {
            outline: none; 
        }
        
        .cta:hover {
            transition: 0.5s;
            box-shadow: 10px 10px 0 black;
        }
        
        .cta span:nth-child(2) {
            transition: 0.5s;
            margin-right: 0px;
        }
        
        .cta:hover  span:nth-child(2) {
            transition: 0.5s;
            margin-right: 45px;
        }
        
        span {
            transform: skewX(15deg) 
        }
        
        span:nth-child(2) {
            width: 20px;
            margin-left: 30px;
            position: relative;
            top: 12%;
        }
        
        /**************SVG****************/
        
        path.one {
            transition: 0.4s;
            transform: translateX(-60%);
        }
        
        path.two {
            transition: 0.5s;
            transform: translateX(-30%);
        }
        
        .cta:hover path.three {
            animation: color_anim 1s infinite 0.2s;
        }
        
        .cta:hover path.one {
            transform: translateX(0%);
            animation: color_anim 1s infinite 0.6s;
        }
        
        .cta:hover path.two {
            transform: translateX(0%);
            animation: color_anim 1s infinite 0.4s;
        }
        
        /* SVG animations */
        
        @keyframes color_anim {
            0% {
                fill: white;
            }
            50% {
                fill: black;
            }
            100% {
                fill: white;
            }
        }
    }
}

.questions-image-container {
    img{
        width: 640px;
        height: 360px;
    }
}

.interests-container.animate {
    opacity: 1;
    transform: translateY(0);
}

.questions-container {
    opacity: 0; /* Initial state */
    transform: translateY(120px); /* Initial state */
    transition: opacity 1s ease-out, transform 1s ease-out;

    .questions-heading {
        font-size: 40px;
        color: @primary-color;
    }

    p {
        font-size: 24px;
        color: #555;
    }
}

.questions-container.animate {
    opacity: 1;
    transform: translateY(0);
}
 
@media (hover: none) {
    
    .interests-container {
        .cta {
            box-shadow: 10px 10px 0 black !important;
            font-size: 32px !important;
            padding: 10px 65px !important;
    
        }
    
        .cta path.three {
            animation: color_anim 1s infinite 0.2s;
            transform: translateX(0%);
        }
        .cta path.one {
            animation: color_anim 1s infinite 0.6s;
            transform: translateX(0%);
        }
        .cta path.two {
            animation: color_anim 1s infinite 0.4s;
            transform: translateX(0%);
        }
    }
}

@media (max-width: 1024px) and (hover: none) {
    .interests-questions-container{
        flex-direction: column;
        border-radius: 150px 0px 0px 0px;

        .questions-container {
            margin: 4% 4% 0 4%;
        }
    }
}

@media (max-width: 576px) and (hover: none) {
    .interests-container {
        .interests-heading {
            font-size: 32px !important;
            text-align: center;
        }

        .cta {
            font-size: 24px !important;
            padding: 10px 45px !important;
        }
    
        span:nth-child(2) {
            margin-left: 20px !important;
        }
    }    

    .questions-container {
        p {
            font-size: 16px;
            margin: 0 40px 0 40px;

        }
        .questions-heading {
            font-size: 32px !important;
            text-align: center;
        }
    }
}
@primary-color: #0036B9;@secondary-color: #FFAC00;@content-background-color: #fff;