.why-choose-us-container {
    max-width: 1200px;
    margin: 0 auto;
    text-align: left;
    padding-top: 80px;
}

.why-choose-us-header {
    padding: 12px;
    font-size: 40px;
    text-align: center;
    .why-choose-us-header-1{
        color: @primary-color;
    }
    .why-choose-us-header-2{
        color: @secondary-color;
    }
}

.why-choose-us-sub-header {
    font-size: 20px;
    text-align: center;
    margin: 12px;
    color: rgb(105, 105, 105);
    margin-bottom: 52px;
}

.why-choose-us-title {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 80px;
    text-align: center;
    opacity: 0;
    transform: translateY(120px);
    transition: opacity 0.6s ease-in-out, transform 1s ease-in-out;
}

.why-choose-us-title-1 {
    color: @secondary-color;
}

.why-choose-us-title-2 {
    color: @primary-color;
}

.why-choose-us-content {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.why-choose-us-row-reverse,
.why-choose-us-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    margin-bottom: 80px;
    opacity: 0;
    transform: translateY(120px);
    transition: opacity 0.6s ease-in-out, transform 1s ease-in-out;
}

@media (max-width: 480px) {
    .why-choose-us-row-reverse,
    .why-choose-us-row {
        margin-bottom: 20px;

}
}

.why-choose-us-title.fade-in,
.why-choose-us-row-reverse.fade-in,
.why-choose-us-row.fade-in {
    opacity: 1;
    transform: translateY(0);
}

.why-choose-us-column {
    flex: 1;
}

.text-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.image-column {
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-column img {
    min-width: 640px;
    max-width: 640px;
    min-height: 360px;
    max-height: 360px;
    padding: 24px;
    border: 1px solid @primary-color;
    border-radius: 20px;
    box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.25);
}

.why-choose-us-item {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.why-choose-us-number {
    font-size: 48px;
    color: @primary-color;
    font-weight: bold;
}

.why-choose-us-heading {
    font-size: 24px;
    margin: 10px 0;
}

.why-choose-us-heading span {
    font-size: 18px;
    color: #666;
}

.why-choose-us-description {
    font-size: 16px;
    color: #555;
    line-height: 1.5;
    text-align: justify;
}

.team-illustration {
    width: 100%;
    max-width: 400px;
    height: auto;
}

.why-choose-us-img-skeleton{
    width: 690px !important;
    height: 400px !important;
}

@media (max-width: 1280px) {
    .image-column img {
        min-width: 480px;
        max-width: 480px;
        min-height: 270px;
        max-height: 270px;
    }
    
    .why-choose-us-img-skeleton{
        width: 530px !important;
        height: 320px !important;
    }
}

@media (max-width: 1080px) {
    .image-column img {
        min-width: 320px;
        max-width: 320px;
        min-height: 180px;
        max-height: 180px;
    }

    .why-choose-us-img-skeleton{
        width: 370px !important;
        height: 230px !important;
    }
}

@media (max-width: 820px) {
    .why-choose-us-sub-header {
        margin-bottom: 4px;
    }

    .why-choose-us-content{
        gap: 0px;
    }
    
    .why-choose-us-title {
        margin-bottom: 0px;
    }

    .why-choose-us-row-reverse,
    .why-choose-us-row {
        flex-direction: column;
        gap: 0px;
    }

    .image-column {
        order: 1;
        margin-bottom: 20px;
        margin: 44px;
    }

    .image-column img {
        min-width: 320px;
        max-width: 320px;
        min-height: 180px;
        max-height: 180px;
    }

    .why-choose-us-img-skeleton{
        width: 290px !important;
        height: 185px !important;
    }

    .text-column {
        order: 2;
    }

    .why-choose-us-row:nth-child(odd) .image-column {
        order: 2;
    }

    .why-choose-us-row:nth-child(odd) .text-column {
        order: 1;
    }

    .why-choose-us-row:nth-child(even) .image-column {
        order: 1;
    }

    .why-choose-us-row:nth-child(even) .text-column {
        order: 2;
    }

    .team-illustration {
        max-width: 100%;
    }
}

@media (max-width: 768px) {
    .why-choose-us-header {
        font-size: 32px;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }

    .why-choose-us-sub-header {
        font-size: 16px;
    }

    .image-column img {
        min-width: 240px;
        max-width: 240px;
        min-height: 135px;
        max-height: 135px;
    }
}

@primary-color: #0036B9;@secondary-color: #FFAC00;@content-background-color: #fff;