.privacy-policy-container-content {
    width: 100%;
    margin: auto;
    padding-top: 80px;
    padding-bottom: 96px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.bizmate-logo-pp{
    margin-top: 20px;
}
  
.privacy-policy-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
  
.privacy-policy-heading {
    font-size: 56px;
    color: #000000;
    margin: 0px;
}
  
.pp-text {
    font-size: 20s; 
    color: black;
    line-height: 20px; 
    text-align: left;
    display: flex;
    flex-direction: column;
    width: 80%;
}

.pp-text-header-1 {
    font-weight: bold;
}

.pp-text-body-1{
    margin-bottom: 0px;
}

.inner-ol {
    list-style-type: upper-roman;
}

@media (max-width: 576px) {
    .bizmate-logo-pp{
        margin-top: 0px;
    }

    .privacy-policy-heading {
        font-size: 40px;
        color: #000000;
        margin: 0px;
    }
}
@primary-color: #0036B9;@secondary-color: #FFAC00;@content-background-color: #fff;