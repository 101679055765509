/* AboutUs.less */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.why-bizmate-skeleton {
  width: 440px !important;
  height: 440px !important;
}
.why-bizmate-heading-1 {
  color: #FFAC00;
}
.ant-skeleton-image-svg {
  width: 152px !important;
  height: 152px !important;
}
.why-bizmate-image-column {
  height: 440px;
  opacity: 0;
  /* Initial state */
  transform: translateY(120px);
  /* Initial state */
  transition: opacity 1s ease-out, transform 1s ease-out;
}
.why-bizmate-image-column.animate {
  opacity: 1;
  transform: translateY(0);
}
.why-bizmate-text-column {
  display: flex;
  flex-direction: column;
  width: 50%;
  opacity: 0;
  /* Initial state */
  transform: translateY(120px);
  /* Initial state */
  transition: opacity 1s ease-out, transform 1s ease-out;
}
.why-bizmate-text-column.animate {
  opacity: 1;
  transform: translateY(0);
}
.why-bizmate-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #fdf6ed;
  border-radius: 150px 0px 150px 0px;
  margin: auto;
  min-height: 70vh;
  align-items: center;
  justify-content: space-around;
}
.why-bizmate-image {
  width: 100%;
  height: 100%;
}
.why-bizmate-heading {
  font-size: 48px;
  font-weight: 900;
  line-height: 1.2;
  color: #0036B9;
  margin: 0px;
}
.why-bizmate-description-1 {
  font-size: 16px;
  line-height: 1.5;
  color: #696969;
  margin-top: 16px;
  text-align: justify;
}
.why-bizmate-btn {
  padding: 12px 24px;
  background-color: #0036B9;
  color: white;
  text-decoration: none;
  border-radius: 9999px;
  transition: background-color 300ms;
}
.why-bizmate-btn:hover {
  background-color: #667EEA;
}
.why-bizmate-btn-content {
  margin-top: 16px;
}
@media (max-width: 1024px) {
  .why-bizmate-text-column {
    margin: 52px;
    align-items: center;
    text-align: justify;
    width: fit-content;
  }
  .why-bizmate-container {
    justify-content: center;
    flex-direction: column;
    min-height: 50vh;
  }
  .why-bizmate-heading {
    font-size: 38px;
    font-weight: 700;
    margin-top: 28px;
  }
  .why-bizmate-image-column {
    height: 300px;
  }
  .why-bizmate-description {
    font-size: 16px;
  }
  .why-bizmate-skeleton {
    width: 300px !important;
    height: 300px !important;
  }
  .ant-skeleton-image-svg {
    width: 152px !important;
    height: 152px !important;
  }
}
@media (max-width: 820px) {
  .why-bizmate-heading {
    font-size: 26px;
    font-weight: 700;
  }
  .why-bizmate-image-column {
    height: 200px;
  }
  .why-bizmate-description {
    font-size: 12px;
  }
  .why-bizmate-skeleton {
    width: 200px !important;
    height: 200px !important;
  }
  .ant-skeleton-image-svg {
    width: 120px !important;
    height: 120px !important;
  }
}
@media (max-width: 769px) {
  .why-bizmate-image-column {
    height: 240px;
  }
  .why-bizmate-skeleton {
    width: 240px !important;
    height: 240px !important;
  }
  .ant-skeleton-image-svg {
    width: 96px !important;
    height: 96px !important;
  }
}
@media (max-width: 576px) {
  .why-bizmate-container {
    border-radius: 0px 92px 0px 0px;
  }
  .why-bizmate-heading {
    text-align: center;
  }
  .why-bizmate-description {
    text-align: center;
    font-size: 12px;
  }
  .why-bizmate-image {
    padding-top: 12%;
  }
}
