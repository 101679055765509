.hero-container {
    position: relative;
    min-height: 80vh;

    &.loading {
        background: black;
    }

    &.loaded {
        background: url('../../assets/images/hero-background.jpg') center/cover no-repeat;
    }
}

.language-switcher-container-hero {
    display: none;
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 30;
}

.opacity-overlay {
    position: absolute;
    inset: 0;
    // background-color: rgba(49, 46, 129, 0.25);
    z-index: 10;
}

.hero-inner-container {
    position: relative;
    padding: 24px 32px;
    max-width: 1280px;
    margin: auto;
    z-index: 20;
    opacity: 0; /* Initial state */
    transform: translateY(120px); /* Initial state */
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.hero-inner-container.animate {
    opacity: 1;
    transform: translateY(0);
}

.left-column {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    padding-top: 96px;
    padding-bottom: 128px;
    margin-left: 0px;
    margin-right: 32px;
    margin-top: 72px;
}

.heading {
    margin-top: 0px;
    color: #fff;
    font-size: 48px;
    font-weight: 900;
    text-align: left;
}

.slanted-background {
    display: inline-block;
    color: @primary-color;
    background-color: #ffffff;
    font-style: italic;
    // transform: skewX(-16deg);
}

.notification {
    color: #ffffff;
    padding: 4px 12px;
    margin: 8px;
    border-left: 4px solid @secondary-color;
    font-size: 16px;
}

.primary-action {
    background-color: @primary-color;
    padding: 16px 32px;
    font-size: 20px;
    border-radius: 4px;
    border-width: 0px;
    transition: background-color 0.3s, color 0.3s;
    cursor: pointer;
    width: fit-content;
    margin-top: 16px;

    .primary-action-link {
        color: white;
        text-decoration: none;
    }
}

.primary-action:hover {
    background-color: white;

    .primary-action-link {
        color: @primary-color;
    }
}

.bizmate-logo {
    display: none;
    flex-direction: column;
    align-items: center;
}

.bizmate-logo-hero {
    width: 180px;
}

.bizmate-title {
    color: white;
    font-size: 48px;
    font-weight: 700;
    margin: 0px;
}

.arrow-container {
    display: none;
}

.signup-btn-hero {
    display: none;
}

@media (max-width: 1024px) {
    .hero-container {
        min-height: 50vh;
    }
}

@media (max-width: 820px) {
    .about-us-btn-hero{
        display: none;
    }

    .signup-btn-hero{
        display: block;
    }

    .bizmate-logo {
        display: flex;
    }

    .bizmate-logo-hero {
        width: 120px;
    }

    .left-column {
        margin-top: 20px;
        margin-right: 0px;
        padding-top: 0px;
        align-items: center;
    }

    .heading {
        text-align: center;
        font-size: 40px;
    }

    .notification {
        font-size: 12px;
    }

    .primary-action {
        font-size: 12px;
        padding: 12px 24px;
    }
}

@media (max-width: 576px) {
    .language-switcher-container-hero{
        display: block;
    }

    .bizmate-logo-hero {
        width: 100px;
    }

    .hero-container {
        min-height: 100vh;
    }

    .hero-inner-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 100vh;
    }

    .notification {
        font-size: 12px;
    }

    .heading {
        font-size: 24px;
    }

    .primary-action {
        font-size: 12px;
    }

    .arrow-container {
        display: block;
        width: 25px;
        animation: bounce 1600ms infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
        cursor: pointer;
        height: 20px;
    }
      
    .arrow-down {
        height: 6px;
        background: white;
        transform: rotate(45deg);
        transform-origin: 0% 0%;
        border-radius: 5px;
    }

    .arrow-down:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        bottom: 0;
        left: 100%;
        border-radius: 5px;
        background: white;
        transform: rotate(-90deg);
        transform-origin: 0% 100%;
    }
      
    @keyframes bounce {
        50% {
            transform: translateY(-15px);
        }
    }
}

@primary-color: #0036B9;@secondary-color: #FFAC00;@content-background-color: #fff;