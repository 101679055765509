.transparent-header {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0);
    backdrop-filter: blur(8px);
    z-index: 1000;
    padding: 10px 20px;
    box-sizing: border-box;

    .header-logo-link{
        text-decoration: none;
    }

    .nav-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .logo-container {
        display: flex;
        align-items: center;

        .logo-image-small {
            width: 48px;
        }

        .company-name {
            margin-left: 10px;
            color: #fff;
            font-size: 20px;
            font-weight: bold;
        }

        &.blacked .company-name {
            color: #000;
        }

        @media (max-width: 769px) {
            .company-name {
                display: none;
            }
        }
    }

    .navigation-menu {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;

        li {
            margin: 0 15px;

            a {
                text-decoration: none;
                color: #fff;
                font-size: 16px;

                &:hover {
                    color: #ccc;
                }
            }
        }
    }

    &.blacked a {
        color: #000 !important;
        &:hover {
            color: @primary-color !important;
        }
    }

    .language-switcher-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
    }

    .member-login-button {
        background-color: @primary-color;
        padding: 12px;
        border: none;
        border-radius: 20px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
        transition: background-color 0.3s, color 0.3s;

        a {
            color: white !important;
            text-decoration: none;
        }
    }

    .member-login-button:hover {
        background-color: white;
        
        a {
            color: @primary-color !important;
            text-decoration: none;
        }
    }
}

@media (max-width: 820px) {
    .transparent-header {
        display: none;
    }
}

@primary-color: #0036B9;@secondary-color: #FFAC00;@content-background-color: #fff;