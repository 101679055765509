.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1090; /* Ensure this is lower than the menu-circle-button */
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background-color: @primary-color;
    color: white;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
    visibility: hidden;
}

.scroll-to-top.visible {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}

.arrow {
    border: solid white;
    border-width: 0 3px 3px 0; 
    display: inline-block;
    padding: 4px;
}

.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}
@primary-color: #0036B9;@secondary-color: #FFAC00;@content-background-color: #fff;