.menu-circle-button {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1100; /* Ensure this is higher than the scroll-to-top button */
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background-color: @primary-color;
    color: white;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);

    .bar {
        display: block;
        height: 4px;
        width: 75%;
        border-radius: 10px;
        background: #ffffff;
        margin-top: 4px;
        transition: margin 0.2s, transform 0.2s;
    }

    .close.bar:first-child {
        transform: translateY(7px) rotate(45deg);
        background: black;
    }

    .close.bar:nth-child(2) {
        transform: scaleY(0);
        opacity: 0;
    }

    .close.bar:last-child {
        transform: translateY(-9px) rotate(-45deg);
        background: black;
    }
}

.menu-circle-button.close {
    background-color: @secondary-color;
}

.header-collapse-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 1050; /* Adjust accordingly to ensure it's behind the button */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    opacity: 0;
    transform: scale(1.1);
    transition: opacity 0.3s ease, transform 0.3s ease;
    pointer-events: none; /* Disable interactions when modal is not open */
}

.header-collapse-modal.active {
    opacity: 1;
    transform: scale(1);
    pointer-events: auto; /* Enable interactions when modal is open */
}

.header-collapse-modal-nav ul {
    list-style: none;
    text-align: center;
    padding: 0;
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.header-collapse-modal.active .header-collapse-modal-nav ul {
    opacity: 1;
    transform: translateY(0);
}

.header-collapse-modal-nav li {
    margin: 20px 0;
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.header-collapse-modal.active .header-collapse-modal-nav li {
    opacity: 1;
    transform: translateX(0);
}

.header-collapse-modal-nav li:nth-child(1) {
    transition-delay: 0.1s;
}

.header-collapse-modal-nav li:nth-child(2) {
    transition-delay: 0.2s;
}

.header-collapse-modal-nav li:nth-child(3) {
    transition-delay: 0.3s;
}

.header-collapse-modal-nav li:nth-child(4) {
    transition-delay: 0.4s;
}

.header-collapse-modal-nav li:nth-child(5) {
    transition-delay: 0.5s;
}

.header-collapse-modal-nav li:nth-child(6) {
    transition-delay: 0.6s;
}

.header-collapse-modal-nav li:nth-child(7) {
    transition-delay: 0.7s;
}

.header-collapse-modal-nav li:nth-child(7) {
    transition-delay: 0.7s;
}

.header-collapse-modal-nav li:nth-child(9) {
    transition-delay: 0.8s;
}

.header-collapse-modal-nav li:nth-child(11) {
    transition-delay: 0.9s;
}

.language-switcher-header-mobile{
    text-align: center;
    align-items: center;
    justify-content: center;
}

.header-collapse-modal-nav a {
    color: white;
    text-decoration: none;
    font-size: 24px;
    transition: color 0.3s ease;

    &:hover {
        color: #ccc;
    }
}

.header-collapse {
    display: none;
}

.member-login-btn-collapse{
    padding: 12px 24px;
    font-size: 16px;
    border-radius: 12px;
    border-width: 0px;
    background-color: @primary-color;
    color: white;
}

@media (max-width: 820px) {
    .header-collapse {
        display: block;
    }
}
@primary-color: #0036B9;@secondary-color: #FFAC00;@content-background-color: #fff;