@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.features-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    margin: auto;
    padding: 80px 0;

    h2 {
        text-align: center;
    }
}

.emphasise-keywords {
    font-style: italic;
    font-weight: bold;
    color: @primary-color !important;
}

.features-heading {
    font-size: 40px;
    text-align: center;
    opacity: 0; /* Initial state */
    transform: translateY(120px); /* Initial state */
    transition: opacity 1s ease-out, transform 1s ease-out;
    .features-heading-1 {
        color: @primary-color;
    }
    .features-heading-2 {
        font-size: 32px;
        color: @secondary-color;
    }
}

.feature-description-panel-1.animate,
.feature-description-panel-2.animate,
.features-heading.animate,
.carousel-container.animate {
    opacity: 1;
    transform: translateY(0);
}


.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
    color: currentColor;
    z-index: 2;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover {
    left: 10px;
    z-index: 2;
    color: @primary-color;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover {
    right: 10px;
    z-index: 2;
    color: @primary-color;
}

/* Style for the carousel dots */
.ant-carousel .slick-dots li button {
    margin-top: 24px;
    color: currentColor;
    background-color: @primary-color;

    transition: background-color 0.3s ease;
  }
  
  .ant-carousel .slick-dots li.slick-active button {
    background-color: @secondary-color;
  }
  
  .ant-carousel .slick-dots li button:hover {
    background-color: @primary-color;
  }

.carousel-container {
    width: 100%;
    height: auto;
    background-color: #CCE3FF;
    padding: 2%;
    border-radius: 20px;
    margin-top: 4%;
    opacity: 0; /* Initial state */
    transform: translateY(120px); /* Initial state */
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.carousel-content img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

.feature-description-panel-1,
.feature-description-panel-2 {
    display: flex;
    text-align: justify;
    opacity: 0; /* Initial state */
    transform: translateY(120px); /* Initial state */
    transition: opacity 1s ease-out, transform 1s ease-out;
    .ctms-description,
    .resolution-description,
    .annual-return-description,
    .mbrs-report-description {
        flex: 1;
        padding:8%;
        span{
            font-size: 16px;
            color: rgb(105, 105, 105);
        }
    }
}

.feature-description-panel-1 {
    .ctms-description{
        background-color: #ffdd99;
        border-radius: 150px 0 150px 0px;
    }
    
    .resolution-description{
        background-color: #c2d4ff;
        border-radius: 0 150px 0px 150px;
    }
}

.feature-description-panel-2 {
    .annual-return-description{
        background-color: #c2d4ff;
        border-radius: 0px 150px 0px 150px;
    }
    
    .mbrs-report-description{
        background-color: #ffdd99;        
        border-radius: 150px 0px 150px 0px;
    }
}

@media (max-width:1024px) {

        .annual-return-description {
            margin-bottom: 4%;
        }

        .mbrs-report-description {
            padding: 4%;
            margin-bottom: 4%;
        }

    .carousel-container {
        width: 84%;
        padding: 4%;
    }

    .ant-carousel .slick-dots li button {
        margin-top: 28px;
    }
}

@media (max-width: 820px) {
    .features-heading {
        font-size: 36px;
    }

    .features-subheading {
        font-size: 20px;
    }

}

@media (max-width: 760px) {
    .feature-description-panel-1,
    .feature-description-panel-2 {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 576px) {
    .features-heading {
        font-size: 32px;
        padding-left: 12px;
        padding-right: 12px;
    }

    .features-subheading {
        font-size: 16px;
    }

    .ctms-description,
    .annual-return-description {
        background-color: #ffdd99 !important; 
        padding-left: 8%;
        margin-bottom: 4%;
    }

    .resolution-description,
    .mbrs-report-description {
        background-color: #c2d4ff !important;
        padding: 4% 4% 8% 8%;
        margin-bottom: 4%;
    }
    
    .ctms-description,
    .resolution-description,
    .mbrs-report-description,
    .annual-return-description{
        border-radius: 92px 32px 92px 32px !important;
        margin-left: 4%;
        margin-right: 4%;
        margin-bottom: 4%;
    }

    .ant-carousel .slick-dots li button {
        margin-top: 20px;
    }
}
@primary-color: #0036B9;@secondary-color: #FFAC00;@content-background-color: #fff;