@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.product-overview-container {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    margin: auto;
    padding-top: 80px;
    padding-bottom: 96px;
    margin: 4% 0% 8% 0%;
    align-items: center;
    justify-content: space-around;
}

.product-overview-image-column {
    border: 1px solid rgb(187, 187, 187);
    border-radius: 12px;
    padding: 12px;
    box-shadow: 6px 6px 3px 0px rgba(0,0,0,0.25);
    opacity: 0; /* Initial state */
    transform: translateY(120px); /* Initial state */
    transition: opacity 1s ease-out, transform 1s ease-out;

    img {
        width: 640px;
        height: 360px;
    }
}

.product-overview-image-column.animate {
    opacity: 1;
    transform: translateY(0);
}

.product-overview-text-column {
    display: flex;
    flex-direction: column;
    width: 50%;
    opacity: 0; /* Initial state */
    transform: translateY(120px); /* Initial state */
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.product-overview-text-column.animate {
    opacity: 1;
    transform: translateY(0);
}

.product-overview-heading {
    font-size: 40px;
    font-weight: 900;
    line-height: 1.2;
    color: @primary-color;
    margin: 0px;
}

.product-overview-description {
    font-size: 16px;
    line-height: 1.5;
    color: rgb(105, 105, 105);
    margin-top: 16px;
    text-align: justify;
}

.product-overview-btn-content {
    margin-top: 16px;
}

.product-overview-btn {
    padding: 12px 24px;
    background-color: @primary-color;
    color: white;
    text-decoration: none;
    border-radius: 9999px;
    transition: background-color 300ms;
}

.product-overview-btn:hover {
    background-color: #667EEA;
}

@media (max-width: 1350px) { 
    .product-overview-image-column {
        img {
            width: 500px;
            height: 250px;
        }
    }
}

@media (max-width: 1080px) {
    .product-overview-text-column {
        margin: 52px;
        align-items: center;
        text-align: justify;
        width: fit-content;
    }

    .product-overview-container {
        justify-content: center;
        flex-direction: column;
        min-height: 50vh;
    }

    .product-overview-heading {
        font-size: 38px;
        font-weight: 700;
        margin-top: 28px;
    }

    .product-overview-description {
        font-size: 16px;
    }
}

@media (max-width: 820px) {
    .product-overview-heading {
        font-size: 26px;
        font-weight: 700;
    }

    .product-overview-description {
        font-size: 16px;
    }
}

@media (max-width: 769px) {
    .product-overview-image-column {
        img {
            width: 400px;
            height: 200px;
        }
    }
}

@media (max-width: 576px) {
    .product-overview-heading {
        text-align: center;
    }

    .product-overview-description {
        text-align: justify;
        font-size: 16px;
    }
}

@media (max-width: 460px) {
    .product-overview-image-column {
        img {
            width: 350px;
            height: 175px;
        }
    }
}

@media (max-width: 390px) {
    .product-overview-image-column {
        img {
            width: 300px;
            height: 150px;
        }
    }
}

@primary-color: #0036B9;@secondary-color: #FFAC00;@content-background-color: #fff;